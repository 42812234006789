import * as React from "react"
import { Layout } from "../components/layout"
import { Card, Col, Container, Row } from "react-bootstrap"
import { Envelope, GeoAlt, Telephone } from "react-bootstrap-icons"
import { Seo } from "../components/seo"
import { getSrc } from "gatsby-plugin-image"

function ContactCard({ icon, title, href, text }) {
  return (
    <Col xs={12} md={4}>
      <Card>
        <Card.Body className="py-5 text-center">
          <h2 className="mb-5">{icon}</h2>
          <h3>{title}</h3>
          <p className="mb-0">
            {
              href
                ? (
                  <a href={href}>{text}</a>
                )
                : text
            }
          </p>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default function Contact() {
  return (
    <Layout>
      <Container className="pt-7 pb-4">
        <h1 className="display-2 fw-bold text-center">Contact</h1>
        <Row>
          <ContactCard title="E-mail" icon={<Envelope/>} href="mailto:heidi@zelfgemaaktlekkers.be" text="heidi@zelfgemaaktlekkers.be" />
          <ContactCard title="Telefoon" icon={<Telephone/>} href="tel:+32488014554" text="+32 488 014 554" />
          <ContactCard title="Adres" icon={<GeoAlt/>} text="Nieuwstraat 77, 3511 Hasselt" />
        </Row>
      </Container>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Contact"
  />
)
